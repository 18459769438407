/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/LinkExperimentWorkflow
 * @desc Link an experiment workflow to an experiment workflow
 */

import EntityActionEditModal from 'labstep-web/components/Entity/Action/Edit/Modal';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import SearchSelect from 'labstep-web/core/Select/Search';
import { ExperimentWorkflowLink } from 'labstep-web/models/experiment-workflow-link.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import React from 'react';
import {
  ExperimentWorkflowActionLinkExperimentWorkflowContainerProps,
  ExperimentWorkflowActionLinkExperimentWorkflowProps,
} from './types';

export const ExperimentWorkflowActionLinkExperimentWorkflow: React.FC<
  ExperimentWorkflowActionLinkExperimentWorkflowProps
> = ({
  experimentWorkflow,
  viewComponent,
  modalProps,
  create,
  options,
}) => (
  <EntityActionEditModal
    entity={experimentWorkflow}
    header="Link Experiment"
    viewComponent={
      viewComponent ||
      (({ toggleModal }) => (
        <ActionComponent
          type="button"
          text="New Link"
          onClick={toggleModal}
        />
      ))
    }
    content={({ toggleModal }) => (
      <SearchSelect
        labelKey="displayName"
        autoFocus
        openMenuOnFocus
        params={{
          not_id: experimentWorkflow.id,
          serializerGroups: 'paginator,partial',
        }}
        entityName={ExperimentWorkflow.entityName}
        value={null}
        onChange={(option) => {
          create(
            {
              src_id: experimentWorkflow.id,
              dest_id: option.id,
            },
            options,
          );
          toggleModal();
        }}
      />
    )}
    {...modalProps}
  />
);

export const ExperimentWorkflowActionLinkExperimentWorkflowContainer: React.FC<
  ExperimentWorkflowActionLinkExperimentWorkflowContainerProps
> = (props) => (
  <EntityCreateContainer
    entityName={ExperimentWorkflowLink.entityName}
  >
    {({ create, status }) => (
      <ExperimentWorkflowActionLinkExperimentWorkflow
        create={create}
        status={status}
        {...props}
      />
    )}
  </EntityCreateContainer>
);

export default ExperimentWorkflowActionLinkExperimentWorkflowContainer;
