/**
 * Labstep
 *
 * @module components/OrderRequest/Action/Create/Wizard
 * @desc OrderRequest create wizard
 */

import { useHasAccessCreate } from 'labstep-web/components/Entity/Can';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { useEntity } from 'labstep-web/hooks/entity';
import { ModalRouteStateType } from 'labstep-web/hooks/modalRoute/types';
import { APICallOptions } from 'labstep-web/models';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { Resource } from 'labstep-web/models/resource.model';
import { selectEntity } from 'labstep-web/state/selectors/entity';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import OrderRequestActionCreateWizardStepsSelectResource from './Steps/SelectResource';
import OrderRequestActionCreateWizardStepsSetDetails from './Steps/SetDetails';

export const ORDER_REQUEST_CREATE_ROUTE_ID = 'order_request_create';

export const OrderRequestActionCreateWizard: React.FC = () => {
  const { activeGroup } = useActiveGroup();
  const [resourceId, setResourceId] = React.useState<
    Resource['idAttr'] | null
  >(null);
  const [orderRequestId, setOrderRequestId] = React.useState<
    OrderRequest['idAttr'] | null
  >(null);

  const { state: locationState } = useLocation<ModalRouteStateType>();
  useEffect(() => {
    if (locationState?.resourceId) {
      setResourceId(locationState?.resourceId);
    }
  }, [locationState?.resourceId]);

  const entity = useEntity(OrderRequest.entityName, orderRequestId);
  const resource: Resource | null = useSelector((state) =>
    resourceId
      ? selectEntity(state, Resource.entityName, resourceId)
      : null,
  );
  const orderRequest: OrderRequest | null = useSelector((state) =>
    orderRequestId
      ? selectEntity(state, OrderRequest.entityName, orderRequestId)
      : null,
  );
  const reset = useCallback(() => {
    setResourceId(null);
    setOrderRequestId(null);
  }, []);

  const canCreate = useHasAccessCreate('order_request');

  if (!canCreate) {
    return null;
  }

  return (
    <ModalWizard
      routeId={ORDER_REQUEST_CREATE_ROUTE_ID}
      status={
        orderRequestId ? entity.update.status : entity.create.status
      }
      mode="mini"
      title="New Request"
      onClose={reset}
      steps={[
        `Select ${Resource.getHumanReadableEntityName(false, true)}`,
        'Request Details',
      ]}
      modalConfirmationProps={
        orderRequestId
          ? {
              header: 'Unsaved changes',
              message:
                'Are you sure you want to discard this request?',
              onConfirm: ({ toggleModal }) =>
                entity.delete.call({
                  onSuccess: () => toggleModal(),
                }),
              status: entity.delete.status,
            }
          : undefined
      }
    >
      <ModalWizard.Step
        index={0}
        description="First you must select the Resource you would like to request. You can select an existing Resource, or create a new one. All required metadata fields must be filled in before you can proceed."
        onContinue={({ goForward, setErrorMessages }) => {
          if (!resource) {
            setErrorMessages(['You must select a Resource']);
            return;
          }
          if (!resource.template) {
            setErrorMessages(['You must select a Category']);
            return;
          }
          const requiredMetadataWithoutValueLength =
            resource.requiredMetadatasWithoutValue.length;
          if (requiredMetadataWithoutValueLength > 0) {
            setErrorMessages([
              `${requiredMetadataWithoutValueLength} required field missing`,
            ]);
            return;
          }
          const body = {
            resource_id: resourceId,
            source_id: resource?.order_request_template?.deleted_at
              ? null
              : resource?.order_request_template?.id,
            template_id:
              activeGroup?.default_order_request_template_id,
            quantity: 1,
          };
          const options = {
            onSuccess: ({ response }) => {
              if (!response) {
                return;
              }
              setOrderRequestId(response.result as any);
              goForward();
            },
          } as APICallOptions;
          entity[orderRequestId ? 'update' : 'create'].call(
            body,
            options,
            locationState?.purchaseOrderId
              ? PurchaseOrder.entityName
              : undefined,
            locationState?.purchaseOrderId,
          );
        }}
      >
        <OrderRequestActionCreateWizardStepsSelectResource
          resourceId={resourceId}
          setResourceId={setResourceId}
        />
      </ModalWizard.Step>
      <ModalWizard.Step
        index={1}
        description="Now you can add any details specific to this request such as a project or grant code. To create a template for request details, go to Workspace settings under Ordering."
        onContinue={({ goForward, setErrorMessages }) => {
          const requiredMetadataWithoutValueLength =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            orderRequest!.requiredMetadatasWithoutValue.length;
          if (requiredMetadataWithoutValueLength > 0) {
            setErrorMessages([
              `${requiredMetadataWithoutValueLength} required field missing`,
            ]);
            return;
          }
          goForward();
        }}
      >
        <OrderRequestActionCreateWizardStepsSetDetails
          orderRequestId={orderRequestId}
        />
      </ModalWizard.Step>
    </ModalWizard>
  );
};

export default OrderRequestActionCreateWizard;
